/**
*完成携程外派订单
*携程(sourceType值12) 飞猪15 同城 19 客服下单 11  待出行订单(OrderStatus值41) 外派司机(driverGuiderNo为null)状态显示
*/
<template>
    <el-button size="small"
               type="text"
               class="btn-border-e6a23c"
               :loading="loading"
               @click="handleClick"
               v-if="showButton">
        完成订单
    </el-button>
</template>

<script type="text/ecmascript-6">
import orderUrls from '@/www/urls/orderUrls'

export default {
    //定义模版数据
    data() {
        return {
            loading: false
        }
    },
    props: ["info"],
    //计算属性
    computed: {
        showButton() {
            const info = this.info;
            return info && [12, 15, 19, 11, 25, 28, 20].includes(info.sourceType) && [31, 41, 42, 43, 51].includes(info.orderStatus)
        }
    },
    //定义事件方法
    methods: {
        async handleClick() {
            this.loading = true;
            try {
                await this.$confirm('确认完成订单?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                const ret = await orderUrls.finishBackendDriverOrder(this, this.info.orderId);
                if (ret.success && ret.data) {
                    this.$emit("success", {
                        ...this.info,
                        orderStatus: 61,
                        orderStatusName: '已完成'
                    });
                    this.$message.success("完成外派成功！")
                }
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        }
    }
}
</script>
